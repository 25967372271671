import { Component } from "react";
import { BiError } from "react-icons/bi";

import Source from "../Source";
import AppContext from "../../Context/AppContext";
import AdminCvDatabase from "./AdminCvDatabase";
import SuperAdminCvDatabase from "./SuperAdminCvDatabase";
import { Container } from "../Source/styledComponent";

class CvDatabase extends Component {

    render() {
        const { role } = this.context;

        if (role !== 'HR ADMIN' && role !== 'SUPER ADMIN' && role !== 'CV CONTROLLER'){
            return(
                <Source>
                <Container style={{flexDirection: 'column'}}>
                    <BiError size={60} />
                    <h2>Page Not Found</h2>
                </Container>
                </Source>
            )
        }

        return (
            <>
                { role === "SUPER ADMIN" ? (
                    <SuperAdminCvDatabase />
                ) : (
                    <AdminCvDatabase />
                )
                }
            </>
        );
    }
}

CvDatabase.contextType = AppContext;

export default CvDatabase;
