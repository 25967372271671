import { Component } from "react";
import { BiError } from "react-icons/bi";

import Source from "../../Source";
import AppContext from "../../../Context/AppContext";
import AdminEmployeeDetails from "./AdminEmployeeDetails";
import SuperAdminEmployeeDetails from "./SuperAdminEmployeeDetails";
import { Container } from "../../Source/styledComponent"

class EmployeeDetails extends Component {

    render() {
        const { role } = this.context;

        if (role !== 'HR ADMIN' && role !== 'SUPER ADMIN'){
            return(
                <Source>
                <Container style={{flexDirection: 'column'}}>
                    <BiError size={60} />
                    <h2>Page Not Found</h2>
                </Container>
                </Source>
            )
        }

        return (
            <>
                { role === "SUPER ADMIN" ? (
                    <SuperAdminEmployeeDetails employeeId={this.props.match.params.employeeId}/>
                ) : (
                    <AdminEmployeeDetails employeeId={this.props.match.params.employeeId}/>
                )
                }
            </>
        );
    }
}

EmployeeDetails.contextType = AppContext;

export default EmployeeDetails;
