import { Component } from "react";
import { BiError } from "react-icons/bi";

import Source from "../Source";
import AppContext from "../../Context/AppContext";
import AdminStandardMail from "./AdminStandardMail";
import SuperAdminStandardMail from "./SuperAdminStandardMail";
import { Container } from "../Source/styledComponent";

class StandardMail extends Component {

    render() {
        const { role } = this.context;

        if (role !== 'HR ADMIN' && role !== 'SUPER ADMIN'){
            return(
                <Source>
                <Container style={{flexDirection: 'column'}}>
                    <BiError size={60} />
                    <h2>Page Not Found</h2>
                </Container>
                </Source>
            )
        }

        return (
            <>
                { role === "SUPER ADMIN" ? (
                    <SuperAdminStandardMail />
                ) : (
                    <AdminStandardMail />
                )
                }
            </>
        );
    }
}

StandardMail.contextType = AppContext;

export default StandardMail;
