import { Component } from "react";

import Source from "../Source";
import AppContext from "../../Context/AppContext";
import SuperAdminTraining from "./SuperAdminTraining";
import AdminTraining from "./AdminTraining";
import UserTraining from "./UserTraining";

class Training extends Component {

    render() {
        const { role, employeeId } = this.context;

        let trainingComponent;
        if (role === 'SUPER ADMIN') {
            trainingComponent = <SuperAdminTraining />;
        } else if (role === 'HR ADMIN') {
            trainingComponent = <AdminTraining />;
        } else {
            trainingComponent = <UserTraining employeeId={employeeId} />;
        }

        return (
            <Source>
                {trainingComponent}
            </Source>
        );
    }
}

Training.contextType = AppContext;

export default Training;
