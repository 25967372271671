import { Component } from "react";
import Modal from 'react-modal';
import Cookies from 'js-cookie'
import { Oval } from "react-loader-spinner"; 
import { BiError } from "react-icons/bi";
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

import { Container, DeleteButton, NoRecordsText, RetryBtn, TableTitle } from "../../../Source/styledComponent";

const apiStatusConstants = {
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE',
}

class SuperAdminPromotionHistoryModal extends Component{
    state = {
        apiStatus: apiStatusConstants.loading,
        promotionHistory: [],
        selectedRows: [],
        clearSelectedRows: false,
    }

    componentDidMount() {
        this.fetchPromotionHistory()
    }

    fetchPromotionHistory = async () => {
        this.setState({apiStatus: apiStatusConstants.loading})
        const {employeeId} = this.props

        try{
            const jwtToken = Cookies.get("jwt_token")
            const options = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/promotion-history/${employeeId}`, options)

            if (!response.ok) {
                this.setState({apiStatus: apiStatusConstants.failure})
                return
            }

            const data = await response.json()

            this.setState({
                promotionHistory: data,
                apiStatus: apiStatusConstants.success,
            });
        } catch (error) {
            this.setState({apiStatus: apiStatusConstants.failure})
        }
        
    }

    handleRowSelected = (rows) => {  
        this.setState({ selectedRows: rows.selectedRows });
    };

    handleDeletePromotionHistory = async () => {
        const pendingToast = toast.loading("Deleting Promotion History...");
        const promotionIds = this.state.selectedRows.map(row => row.promotion_id)
    
        try {
            const jwtToken = Cookies.get("jwt_token");
            const options = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify({promotionIds}),
            };
    
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete-promotion-history`, options);
            const data = await response.json();
    
            if (!response.ok) {
                toast.update(pendingToast, {
                    render: data.failure,
                    type: "error",
                    isLoading: false,
                    autoClose: 4000,
                });
    
                return;
            }
    
            toast.update(pendingToast, {
                render: data.success,
                type: "success",
                isLoading: false,
                autoClose: 4000,
            });
    
            this.fetchPromotionHistory();
            this.setState({clearSelectedRows: true, selectedRows: []})
    
        } catch (error) {
            toast.update(pendingToast, {
                render: "Network error. Please try again later.",
                type: "error",
                isLoading: false,
                autoClose: 4000,
            });
        }
    }

    renderComponent = () => {
        if (this.state.apiStatus === apiStatusConstants.loading) {
            return (
                    <Container>
                    <Oval
                        visible={true}
                        height="40"
                        width="40"
                        color="#3498DB"
                        secondaryColor="#3498DB"
                        ariaLabel="oval-loading"
                    />
                    </Container>                
            )
        }

        if (this.state.apiStatus === apiStatusConstants.failure) {
            return (
                    <Container style={{flexDirection: 'column', textAlign: 'center'}}>
                        <BiError size={60} />
                        <h2>Error Loading Promotion History</h2>
                        <RetryBtn onClick={this.fetchData}>Retry</RetryBtn>
                    </Container>
                
            )
        }

        const columns = [
            {
                name: 'Promotion Date',
                selector: row => row.promotion_date,
                cell: (row) => (
                    <p>{row.promotion_date}</p>
                )
            },
            {
                name: 'Designation',
                selector: row => row.designation,
                cell: (row) => (
                    <p>{row.designation}</p>
                )
            },
            {
                name: 'Department',
                selector: row => row.department,   
                cell: (row) => (
                    <p>{row.department}</p>
                )    
            },
            {
                name: 'Salary',
                selector: row => row.salary, 
                cell: (row) => (
                    <p>{row.salary}</p>
                )      
            },
            {
                name: 'Remarks',
                selector: row => row.remarks,  
                cell: (row) => (
                    <p>{row.remarks}</p>
                )     
            },
        ]

        const cellStyles = {
            headCells: {
              style: {
                fontWeight: "bold",
                fontSize: "14px",
              }
            }
        }

        return(
            <DataTable
                    title={<TableTitle style={{margin: '0px'}}>Promotion History</TableTitle>}
                    columns={columns}
                    data={this.state.promotionHistory}
                    customStyles={cellStyles}
                    pagination
                    persistTableHead
                    selectableRows
                    noContextMenu
                    onSelectedRowsChange={this.handleRowSelected}
                    clearSelectedRows={this.state.clearSelectedRows}
                    noDataComponent={<NoRecordsText>No Promotion History</NoRecordsText>}
                    actions = {
                        <DeleteButton disabled={this.state.selectedRows.length === 0} onClick={this.handleDeletePromotionHistory}>Delete</DeleteButton>    
                    }
                />  

        )


    }

    render(){
        const {isPromotionHistoryModalOpen, closePromotionHistoryModal} = this.props

        const customStyles = {
            content: {
                width: '50%',
                maxHeight: '70%',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                overflowY: 'auto'
            },
        };

        return(
            <Modal
                isOpen={isPromotionHistoryModalOpen}
                onRequestClose={closePromotionHistoryModal}
                style={customStyles}
                contentLabel="Promotion History Modal"
            >
                {this.renderComponent()}
            </Modal>

        )
    }
}

export default SuperAdminPromotionHistoryModal